<template>
<div class="create-class">
  <h1 class="title grey--text"> 创建班级 </h1>
  <v-container class="my-5">
    <v-layout justify-center class="mb-2">
      <v-flex xs12>
        <v-form name="class-form"
          ref="createClassForm"
          v-model="isFormVisiblyValid"
          lazy-validation
        >
          <v-select
            v-model="classInfo.programId"
            :items="programs"
            item-text="title"
            item-value="program_id"
            label="选择项目"
            single-line
            :rules="[required]"
            prepend-icon="ballot"
          ></v-select>
          <v-text-field
            label="班级名称"
            counter="50"
            name="title"
            v-model="classInfo.name"
            :rules ="[required]"
            prepend-icon="home"
          ></v-text-field>
          <v-textarea
            label="描述"
            outline
            v-model="classInfo.description"
            :rules ="[required]"
            prepend-icon="note"
          ></v-textarea>
          <v-select
            v-model="classInfo.tutorIds"
            :items="tutors"
            item-text="tutor"
            item-value="tutor_id"
            chips
            label="任教教师"
            prepend-icon="home"
            multiple
          ></v-select>
          <div class="error" v-if="error">
            {{error}}
          </div>
          <v-btn color='primary'
            @click="create"
            :disabled="!isFormVisiblyValid">
            创建</v-btn>
          <v-btn text @click="cancel">取消</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
// import LessonService from '@/services/LessonService'
import ProgramService from '@/services/ProgramService'
import { ClassStatusLookup, TutorStatusLookup } from '../enums'

export default {
  components: {
  },

  data () {
    return {
      organizationIds: [],
      programIds: [],
      managerIds: [],
      programs: [],
      tutors: [],
      classInfo: {
        name: '',
        description: '',
        programId: '',
        tutorIds: []
      },
      // form validation rules
      required: (value) => !!value || '必填',
      titleRules: [
        v => !!v || '必填项'
      ],
      descriptionRules: [

      ],
      error: null,
      isFormVisiblyValid: true
    }
  },

  async mounted () {
    // set queryingState
    this.$store.dispatch('setQueryingState', true)

    // detect query
    if (this.$route.query.organizationId || this.$route.query.managerId || this.$route.query.programId) { // 如果存在任何路径参数，则优先考虑
      if (this.$route.query.organizationId) {
        this.organizationIds = [this.$route.query.organizationId]
      }
      if (this.$route.query.programId) {
        this.programIds = [this.$route.query.programId]
      }
      if (this.$route.query.managerId) {
        this.managerIds = [this.$route.query.managerId]
      }
    } else { // 如果没有任何中路径参数, 考虑查找当前用户角色
      if (this.$store.state.account.organizations) {
        this.organizationIds = this.$store.state.account.organizations.map(organization => organization.id)
      }
    }

    // get programs list
    let param = {
      programIds: this.programIds.length === 0 ? null : this.programIds,
      organizationIds: this.organizationIds.length === 0 ? null : this.organizationIds,
      managerIds: this.managerIds.length === 0 ? null : this.managerIds
    }

    const res = await ProgramService.getPrograms(param)
    console.log(res)
    this.programs = res.data

    const resTutors = await ProgramService.getTutors({
      organizationIds: this.organizationIds.length === 0 ? null : this.organizationIds,
      statusCodes: [TutorStatusLookup.ACTIVE]
    })
    this.tutors = resTutors.data
    if (this.tutors.length === 1) {
      this.classInfo.tutorIds.push(this.tutors[0].tutor_id)
    }

    // set queryingState
    this.$store.dispatch('setQueryingState', false)
  },

  methods: {
    async create () {
      console.log('#/nCreate ...')
      console.log(this.classInfo)
      this.error = null
      if (!this.isFormVisiblyValid) {
        this.error = '请检查所有必填项.'
      } else {
        // get the selected program's responsible tutor_id, the class's responsible tutor should be the same as program's
        // this.classInfo.tutorId = this.getTutorIdByProgramId(this.classInfo.program_id)
        this.classInfo.statusCode = ClassStatusLookup.INACTIVE // class status: enrolling host 0:inactive(dismissed); 1:active; 2:suspended(temporarily inactive); 3: enrolling host; 4: enrolling student
        const res = await ProgramService.upsertClass(this.classInfo)
        console.log('res: ', res)
        this.$router.go(-1) // 退回前一页面
      }
    },
    cancel () {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
